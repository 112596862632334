import {Box, TextField, Button, InputAdornment} from '@mui/material';
import '@fontsource/poppins';
import {useState} from 'react';
import {VisibilityOff} from '@mui/icons-material';
import logo from "../home/components/header/logo.png";
function LoginFront() {

    const [clicked, setClicked] = useState(false);

    return (
        <div style={{background: "linear-gradient(#244EE3, #101E67)", height: "100%", width: "100%", position: "absolute"}}>
            <Box ml={3} mt={1} mb={2} sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <img style={{objectFit: "cover", width: "2%"}} src={logo} />
                <a href="/" style={{textDecoration: "none", fontFamily: "Poppins", fontSize: "30px", color: "white", fontWeight: "bold", marginLeft: "5px"}}>
                    Stratos
                </a>
            </Box>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: "100px"}}>
                <Box mt={20} mb={2} sx={{width: "30%", backgroundColor: "white", display: "flex", flexDirection: "column", alignItems: "center", margin: "auto", borderRadius: 3}}>
                    <Box mt={3}>
                        <text style={{fontFamily: "Poppins", fontSize: "30px", color: "black", fontWeight: "medium", margin: "auto"}}>
                            Stratos
                        </text>
                    </Box>
                    <Box mt={1}>
                        Login using Email
                    </Box>
                    <Box mt={5} sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "column"}}>
                        {clicked?
                            <TextField id="outlined-basic" label="Email Address" variant="outlined" style={{width: "75%", margin: "auto"}}/>:
                            <TextField id="outlined-basic" label="Email Address" variant="outlined" style={{width: "75%", margin: "auto"}}/>}
                    </Box>
                    <Box mt={2} sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "column"}}>
                        <TextField id="outlined-basic" type="password" label="Password" variant="outlined" style={{width: "75%", margin: "auto"}}>
                            <InputAdornment>
                                <VisibilityOff />
                            </InputAdornment>
                        </TextField>
                    </Box>
                    <Box mt={2} mb={3} sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "column"}}>
                        <Button onClick={() => setClicked(true)} sx={{width: "75%", margin: "auto", backgroundColor: "#244EE3", "&:hover": {
                                backgroundColor: "#244EE3"
                            }}}>
                            <text style={{color: "white", fontFamily: "Poppins"}}> Login</text>
                        </Button>
                    </Box>
                    <Box mb={5} sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "column"}}>
                        {clicked?<text style={{color: "red", fontFamily: "Poppins", margin: "auto"}}>Invalid username or password. Please try again.</text>:<div/>}
                    </Box>
                </Box>
            </div>
        </div>
    );
}
export default LoginFront;