import {Box, Button} from '@mui/material';
import '@fontsource/poppins';
import macro from './macro.png'
import { useState } from 'react';
import graphic from "../../landing/graphic.png";
function MobileMacrovol() {
    const fullScreen =  {
        overflowY: "auto", display: "flex", flexDirection: "column",
        alignItems: "center", margin: "auto", paddingTop: "10%", paddingBottom: "75px"
    }

    const smallScreen = {
        textAlign: "left", marginLeft: "5%", paddingTop: "100px", marginBottom: "75px", margin: "auto"
    };

    const [width, setWidth] = useState(10000)

    window.addEventListener("resize",  function() {
        setWidth(window.innerWidth)
    });

    return (
        <div style={{background: "linear-gradient(#244EE3, #101E67)", borderRadius: 10, display: "flex", width: "90%", margin: "auto", marginTop: "10%", marginBottom: "150px"}}>
            <div style={fullScreen}>
                <div style={{textAlign: "left", marginLeft: "25px", marginRight: "50px", marginBottom: "25px"}}>
                    <Box>
                        <text style={{fontFamily: "Poppins", fontSize: "25px", color: "white", fontWeight: "bold", alignContent: "left", lineHeight: "35px"}}>
                            Macro Risk Management is more important than ever
                        </text>
                        <br/>
                    </Box>
                    <Box mt={3}>
                        <text style={{fontSize: "15px", color: "white", fontWeight: "medium", fontFamily: "Poppins", lineHeight: "25px"}}>
                            FX Volatility is the highest it has been since the COVID-19 Pandemic.
                            And with higher interest rates and shifting geopolitics, this volatility will continue to increase.
                        </text>
                    </Box>
                </div>
                <div style ={{marginTop:"150px", paddingLeft: "5%", paddingRight: "5%", margin: "auto", maxWidth: "800px"}}>
                    <img src={macro} style={{objectFit: "cover", width: "100%"}}/>
                </div>
            </div>
        </div>
    );
}

export default MobileMacrovol;