import '@fontsource/poppins';
import '@fontsource/inter';
import '@fontsource/roboto';
import {Box, Button, Link} from '@mui/material'
import logo from './logo.png'

function Header() {
    return (
        <div style={{position: "fixed", width: "100%", display: "table"}}>
            <div style={{backgroundColor: "#101E67", width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
                <Box pt={1} pb={1}>
                    <a target="_blank" rel="noopener noreferrer" href="https://neo.com" style={{textDecoration:"none", fontSize: "13px", fontFamily: "Roboto", color: "white", fontWeight: "bolder"}}> 🎉 Now Backed by Neo&nbsp; </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://neo.com" style={{textDecoration:"none", fontSize: "12px", fontFamily: "Roboto", color: "white", fontWeight: "bolder"}}> Learn More </a>
                </Box>
            </div>
        <div style={{display: "table-row"}}>
            <div style={{display: "flex", marginLeft: "3%", float: "left", flexDirection: "row", alignItems: "center"}}>
                <Box mt={1} mb={2} sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <img style={{objectFit: "cover", width: "7%"}} src={logo} />
                    <text style={{fontFamily: "Poppins", fontSize: "30px", color: "white", fontWeight: "bold", marginLeft: "5px"}}>
                        Stratos
                    </text>
                </Box>
            </div>
            <div style={{display: "flex", float: "right", flexDirection: "row", alignItems: "center"}}>
                <Box mr={3} mt={2} mb={2}>
                    <Button href="/aboutus">
                        <text style={{fontFamily: "Poppins", fontSize: "15px", color: "white", fontWeight: "bold"}}>
                            About Us
                        </text>
                    </Button>
                </Box>
                <Box mr={3} mt={2} mb={2}>
                    <Button href="/login">
                        <text style={{fontFamily: "Poppins", fontSize: "15px", color: "white", fontWeight: "bold"}}>
                            Login
                        </text>
                    </Button>
                </Box>
                <Box mt={2} mb={2} mr={3}>
                <Button target="_blank" rel="noopener noreferrer" href="https://form.typeform.com/to/ekkDH51x"
                        sx={{backgroundColor: "#FFFFFF", height: "40px", width: "125px",
                            marginLeft: "10px", marginRight: "10px", borderRadius: 15,
                            "&:hover": {
                                backgroundColor: "white"
                            }}}>
                    <text style={{color: "#244EE3", fontFamily: "Poppins", fontSize: "13px", fontWeight: "bolder", alignSelf: "center"}}>
                        Get Started
                    </text>
                </Button>
                </Box>
            </div>
        </div>
        </div>
        )
}

export default Header;