import {Box, Button} from '@mui/material';
import '@fontsource/poppins';
import '@fontsource/inter';
import support from './support.png'
import Icon from '@mdi/react';
import { mdiHeadphones } from '@mdi/js';
import { useState } from 'react';
import macro from "../macrovol/macro.png";
function Support() {
    const fullScreen =  {
        overflowY: "auto", display: "flex", flexDirection: "row",
        alignItems: "center", paddingBottom: "75px"
    }

    const smallScreen = {
        textAlign: "left", marginLeft: "5%", marginBottom: "75px"
    };

    const [width, setWidth] = useState(10000)

    window.addEventListener("resize",  function() {
        setWidth(window.innerWidth)
    });

    return (
        <div style={{borderRadius: 10, display: "flex", width: "70%", margin: "auto", marginTop: "150px", marginBottom: "150px"}}>
            <div style={width > 1000 ? fullScreen : smallScreen}>
                <div style ={{marginTop:"100px", maxWidth: "500px"}}>
                    <img src={support} style={{objectFit: "cover", width: "95%"}}/>
                </div>
                <div style={{textAlign: "left", marginLeft: "15px", marginTop:"100px", marginBottom: "25px"}}>
                    <Box>
                        <text style={{fontFamily: "Poppins", fontSize: "33px", color: "black", fontWeight: "bold", alignContent: "left", lineHeight: "40px"}}>
                            Fortune 500 Quality Service, Fraction of the Price
                        </text>
                        <br/>
                    </Box>
                    <Box mt={1}>
                        <text style={{fontSize: "15px", color: "black", fontWeight: "medium", fontFamily: "Poppins", lineHeight: "15px"}}>
                            Managing FX and commodity risks is expensive, time consuming, and outdated.
                            We remove the headache by providing a system that recommends or completely automates hedging for your business.
                        </text>
                    </Box>
                    <Box mt={5} pt={1} pb={1} pl={1} pr={1} sx={{backgroundColor: "#244EE3", display: "flex", flexDirection: "column", alignItems: "center", width: "fit-content", borderRadius: 3}}>
                        <Icon path={mdiHeadphones} size={1.5} style={{color: "white"}}/>
                    </Box>
                    <Box mt={3}>
                        <text style={{fontSize: "23px", color: "black", fontFamily: "Inter", lineHeight: "30px", fontWeight: "bold"}}>
                            24/7 service along with custom workflows to fit your needs
                        </text>
                    </Box>
                </div>
            </div>
        </div>
    );
}

export default Support;