import {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';

import MobileLanding from './components/mobile/landing'
import Landing from './components/landing';
import Header from './components/header';
import Map from './components/map';
import MobileMap from "./components/mobile/map";
import Support from './components/support';
import MobileSupport from "./components/mobile/support";
import MacroVol from './components/macrovol';
import MobileMacrovol from "./components/mobile/macrovol";
import Scrollheader from './components/scrollheader';
import MobileScrollheader from "./components/mobile/scrollheader";
import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";

function Home() {
    const [scroll, setScroll] = useState(false)
    const {isAuthenticated} = useAuth0()
    const navigate = useNavigate()
    const [mobile, setMobile] = useState(false)

    useEffect(() => {
        if(isAuthenticated) {
            navigate("/dashboard")
        }
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", () =>
                setScroll(window.pageYOffset > 100)
            );
        }
        if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i)) {
            setMobile(true)
        }
    }, []);

    return (
        <div className="App" style={{overflowY: "auto", backgroundColor: "#F5F9FF"}}>
            {scroll ? mobile ? <MobileScrollheader /> : <Scrollheader />: <div />}
            {mobile ? <MobileLanding /> : <Landing />}
            {mobile? <MobileMacrovol />: <MacroVol />}
            {mobile ? <MobileSupport /> : <Support />}
            {mobile ? <MobileMap/> : <Map />}
            <div style={{paddingBottom: "15px"}}>
                <text style={{fontFamily: "Poppins", fontSize: "12px"}}>
                    2023 © DelFi Holdings Co.
                </text>
            </div>
        </div>
    );
}

export default Home;