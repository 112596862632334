import React, {useState} from 'react';
import './App.css';
import '@fontsource/poppins';
import {Routes, Route} from "react-router-dom";
import Home from './pages/home';
import Login from './pages/login';
import Dashboard from './pages/dashboard';
import Workflows from "./pages/workflows";
import {useAuth0} from "@auth0/auth0-react";
import BouncingDotsLoader from './loader';
import LoginFront from "./pages/loginFront"
import AboutUs from "./pages/home/components/aboutus";
import MobileAboutUs from "./pages/home/components/mobile/aboutus";
import AboutPage from "./pages/home/components/aboutpage";

function App() {
    const { isLoading, isAuthenticated} = useAuth0();

    return(
        <Routes>
            <Route path='/' element={isLoading ? <BouncingDotsLoader /> : <Home/>} />
            <Route path='/login' element={<LoginFront />} />
            <Route path='/aboutus' element={<AboutPage />} />
            <Route path="*" element={<Home/>} />
            {/*<Route path='/dashboard' element={!isAuthenticated && !isLoading ? <Login /> : isLoading ? <BouncingDotsLoader /> : <Dashboard />} />*/}
            {/*<Route path='/workflows' element={!isAuthenticated && !isLoading ?  <Login /> : isLoading ? <BouncingDotsLoader /> : <Workflows />} />*/}
        </Routes>
    )
}

export default App;

