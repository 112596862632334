import {Box, Button} from '@mui/material';
import '@fontsource/poppins';
import graphic from './graphic.png';
import {useEffect, useState} from 'react';
import Header from '../header';
function Landing() {
    const fullScreen =  {
        overflowY: "auto", marginBottom: "30px", display: "flex", flexDirection: "row",
        alignItems: "center", margin: "auto", paddingTop: "200px", marginRight: "30px", paddingBottom: "75px"
    }

    const smallScreen = {
        textAlign: "left", marginLeft: "5%", paddingBottom: "100px", marginTop: "150px", marginRight: "30px"
    };

    const [width, setWidth] = useState(10000)
    const [scroll, setScroll] = useState(false);

    window.addEventListener("resize",  function() {
        setWidth(window.innerWidth)
    });

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", () =>
                setScroll(window.pageYOffset > 100)
            );
        }
    }, []);

    return (
        <div style={{background: "linear-gradient(#244EE3, #101E67)", display: "flex"}}>
            {scroll ? <div/ >: <Header /> }
            <div style={width > 1000 ? fullScreen : smallScreen}>
                <div style={{textAlign: "left", marginLeft: "50px", marginRight: "50px", maxWidth: "600px"}}>
                    <Box pl={3} pr={3} sx={{backgroundColor: "rgb(248, 250, 255, 0.5)", width: "fit-content", height: "30px", borderRadius:5, display: "flex", alignItems:"center"}}>
                        <text style={{color: "white", fontWeight: "medium", opacity: 1, fontSize: "13px"}}>
                            Beta
                        </text>
                    </Box>
                    <Box mt={2}>
                        <text style={{fontFamily: "Poppins", fontSize: "50px", color: "white", fontWeight: "bold", alignContent: "left", lineHeight: "70px"}}>
                            Protect Your Business From Macro Risk
                        </text>
                        <br/>
                    </Box>
                    <Box mt={3}>
                        <text style={{fontSize: "18px", color: "white", fontFamily: "Poppins", lineHeight: "30px"}}>
                            We automatically hedge your <b> currency </b> and <b> commodity </b> exposures,
                            bringing predictability back to your business. Get access to
                            <b> 100+ currencies </b> to manage your risk wherever you need without the hassle.

                        </text>
                    </Box>
                    <div style={{marginTop: "75px", marginBottom: "75px"}}>
                        <Box
                                // onClick={gettingStarted}
                                sx={{backgroundColor: "#FFFFFF", height: "60px", width: "187px", display: "flex",
                                    flexDirection: "column", alignItems: "center", borderRadius: 15, marginTop: "30px",
                                    "&:hover": {
                                        cursor: "pointer"
                                    }}}>
                            <a target="_blank" rel="noopener noreferrer" href="https://form.typeform.com/to/ekkDH51x" style={{textDecoration: "none", color: "#244EE3", fontFamily: "Poppins", fontSize: "18px", fontWeight: "bolder", marginTop: "18px"}}>
                                Get Started
                            </a>
                        </Box>
                    </div>
                <div/>
                </div>
                <div style ={{marginTop:"150px", marginLeft: "50px", paddingRight: "5%", margin: "auto", maxWidth: "800px"}}>
                    <img src={graphic} style={{objectFit: "cover", width: "100%"}}/>
                </div>
            </div>
        </div>
    );
}

export default Landing;