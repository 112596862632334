import '@fontsource/poppins';
import '@fontsource/inter';
import '@fontsource/roboto';
import {Box, Button, Link} from '@mui/material'
import logo from "./logo.png";
import './gradienttext.css';

function MobileScrollheader() {
    return (
        <div style={{position: "fixed", width: "100%", display: "table"}}>
            <div style={{backgroundColor: "#101E67", width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
                <Box pt={1} pb={1}>
                    <a target="_blank" rel="noopener noreferrer" href="https://neo.com" style={{textDecoration:"none", fontSize: "13px", fontFamily: "Roboto", color: "white", fontWeight: "bolder"}}> 🎉 Now Backed by Neo&nbsp; </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://neo.com" style={{textDecoration:"none", fontSize: "12px", fontFamily: "Roboto", color: "white", fontWeight: "bolder"}}> Learn More </a>
                </Box>
            </div>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", backgroundColor: "#FFFFFF"}}>
                <div style={{display: "flex", marginLeft: "3%", float: "left", flexDirection: "row", alignItems: "center"}}>
                    <Box mt={1} mb={1} sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <img style={{objectFit: "cover", width: "10%"}} src={logo} />
                        <h1 style={{fontFamily: "Poppins", fontSize: "30px", color: "linear-gradient(#244EE3, #101E67)", fontWeight: "bold", marginLeft: "5px"}}>
                            Stratos
                        </h1>
                    </Box>
                </div>
                <div style={{display: "flex", float: "right"}}>
                    <Box mt={2} mb={2}>
                        <Button href="/aboutus">
                            <text style={{fontFamily: "Poppins", fontSize: "15px", color: "#1935a5", fontWeight: "bold"}}>
                                About
                            </text>
                        </Button>
                    </Box>
                    <Box mt={2} mb={1}>
                        <Button target="_blank" rel="noopener noreferrer" href="https://form.typeform.com/to/ekkDH51x"
                                sx={{backgroundColor: "#1935a5", height: "40px", width: "125px",
                                    marginLeft: "10px", marginRight: "10px", borderRadius: 15,
                                    "&:hover": {
                                        backgroundColor: "#1935a5"
                                    }}}>
                            <text style={{color: "#FFFFFF", fontFamily: "Poppins", fontSize: "13px", fontWeight: "bolder", alignSelf: "center"}}>
                                Get Started
                            </text>
                        </Button>
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default MobileScrollheader;