import map from './map.png'
import {Box} from '@mui/material'
function MobileMap() {
    return (
        <div style={{width: "100%", overflowY: "auto", paddingTop: "5%", paddingBottom: "60px", display: "flex", flexDirection: "row", alignItems: "center"}}>
            <Box pt={5} sx={{width: "90%", background: "linear-gradient(#244EE3, #101E67)", margin: "auto", borderRadius: 4}}>
                <img style={{objectFit: "cover", width: "95%", margin: "auto"}} src={map} />
            </Box>
        </div>
    )
}

export default MobileMap;
