import {Box, TextField, Button, InputAdornment, Avatar} from '@mui/material';
import '@fontsource/poppins';
import {useState} from 'react';
import {VisibilityOff} from '@mui/icons-material';
import logo from "../../header/logo.png";
import barclays from "./barclays.png";
import strategy from "./strategy.png";
import coinbase from "./coinbase.png";
import amazon from "./amazon.png";
import vish from "./vish.png"
import hvr from "./HVRImage.png"
import cd from "./CDImage.png"
function MobileAboutUs() {

    const [clicked, setClicked] = useState(false);

    const [width, setWidth] = useState(10000)

    window.addEventListener("resize",  function() {
        setWidth(window.innerWidth)
    });

    return (
        <div style={{background: "linear-gradient(#244EE3, #101E67)", height: "100%", width: "100%", position: "absolute", overflowY: "auto"}}>
            <Box ml={3} mt={3} mb={2} sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <img style={{objectFit: "cover", width: "7%"}} src={logo} />
                <a href="/" style={{textDecoration: "none", fontFamily: "Poppins", fontSize: "30px", color: "white", fontWeight: "bold", marginLeft: "5px"}}>
                    Stratos
                </a>
            </Box>
            <div style={{alignItems: "center", marginTop: "100px"}}>
                <Box pl={3} pr={3} sx={{backgroundColor: "rgb(248, 250, 255, 0.5)", marginLeft: "7%", width: "fit-content", height: "30px", borderRadius:5, display: "flex", alignItems:"center"}}>
                    <text style={{color: "white", fontWeight: "medium", opacity: 1, fontSize: "13px"}}>
                        About Us
                    </text>
                </Box>
                <div style={{marginTop: "30px", display: "flex", flexDirection: "column"}}>
                    <div style={{marginLeft: "auto", marginRight: "auto", width: "90%", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        <div>
                            <text style={{fontFamily: "poppins", color: "white", fontSize: "30px"}} >
                                What Makes Us Different
                            </text>
                        </div>
                        <br/>
                        <div style={{marginTop: "5px", marginLeft: "auto", marginRight: "auto", width: "90%"}}>
                            <text style={{fontFamily: "poppins", color: "white", fontSize: "15px"}} >
                                Our team has experience hedging international currencies, crafting global risk management strategies,
                                and building sophisticated technology products for Fortune 500 Companies and the world's largest asset managers.
                            </text>
                        </div>
                    </div>
                    <div style={{marginTop: "5%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                            <Box sx={{backgroundColor: "rgb(255, 255, 255, 0.2)", width: "70%", marginBottom: "5%", paddingBottom: "100px",
                                borderWidth: 1, borderColor: "#E2E3E6", borderRadius: 2, position: "relative", marginLeft: "auto", marginRight: "auto"}}>
                                <div style={{display: "flex", justifyContent: "center", marginTop: "15px"}}>
                                    <Avatar alt="V" style={{width: "75px", height: "75px"}}>
                                        <img style={{objectFit: "cover",  filter: "grayscale(1)"}} src={vish} />
                                    </Avatar>
                                </div>
                                <div style={{display: "flex", justifyContent: "center", marginTop: "15px"}}>
                                    <div style={{display: "flex", flexDirection: "column"}}>
                                        <div style={{width: "100%", display: "flex", justifyContent: "center", marginLeft: "2%", marginRight: "2%"}}>
                                            <text style={{color: "white", fontSize: "18px", fontFamily: "poppins"}}>
                                                Vishesh Sharma, CEO
                                            </text>
                                        </div>
                                        <div style={{marginTop: "10px", marginLeft: "10%", marginRight: "10%", textAlign: "center"}}>
                                            <text style={{color: "white", fontSize: "12px", fontFamily: "poppins"}}>
                                                Created global growth and risk strategies
                                                for the largest private equity funds and
                                                F500 companies.
                                            </text>
                                        </div>
                                    </div>
                                </div>
                                <div style={{position: "absolute", bottom: "10px", marginBottom: "10px"}}>
                                <div style={{borderTopWidth: 1, borderColor: "#E2E3E6", width: "90%", margin: "auto"}}>
                                    <text style={{color: "white", fontSize: "12px", fontFamily: "poppins"}}> Previously </text>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                    <Box sx={{width: "27%"}}>
                                        <img style={{objectFit: "cover"}} src={strategy} />
                                    </Box>
                                </div>
                                </div>
                            </Box>
                            <Box sx={{backgroundColor: "rgb(255, 255, 255, 0.2)", paddingBottom: "100px", width: "70%", marginBottom: "5%", marginLeft: "auto", marginRight: "auto", borderWidth: 1,
                                borderColor: "#E2E3E6", borderRadius: 2, position: "relative"}}>
                                <div style={{display: "flex", justifyContent: "center", marginTop: "15px"}}>
                                    <Avatar alt="V" style={{width: "75px", height: "75px"}}>
                                        <img style={{objectFit: "cover",  filter: "grayscale(1)"}} src={hvr} />
                                    </Avatar>
                                </div>
                                <div style={{display: "flex", justifyContent: "center", marginTop: "15px"}}>
                                    <div style={{display: "flex", flexDirection: "column"}}>
                                        <div style={{width: "100%", display: "flex", justifyContent: "center", marginLeft: "2%", marginRight: "2%"}}>
                                            <text style={{color: "white", fontSize: "18px"}}>
                                                Harsha Ramesh, COO
                                            </text>
                                        </div>
                                        <div style={{marginTop: "10px", marginLeft: "10%", marginRight: "10%", textAlign: "center"}}>
                                            <text style={{color: "white", fontSize: "12px"}}>
                                                Executed corporate FX hedging programs for F500 companies as an EM Trader.
                                            </text>
                                        </div>
                                    </div>
                                </div>
                                <div style={{position: "absolute", bottom: "10px", marginBottom: "10px"}}>
                                <div style={{borderTopWidth: 1, borderColor: "#E2E3E6", width: "90%", margin: "auto"}}>
                                    <text style={{color: "white", fontSize: "12px", fontFamily: "poppins"}}> Previously </text>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "center", width: "100%"}}>
                                    <Box sx={{width: "30%"}}>
                                        <img style={{objectFit: "cover"}} src={barclays} />
                                    </Box>
                                </div>
                                </div>
                            </Box>
                            <Box sx={{backgroundColor: "rgb(255, 255, 255, 0.2)", width: "70%", paddingBottom: "100px", marginBottom: "5%", marginLeft: "auto", marginRight: "auto", borderWidth: 1,
                                borderColor: "#E2E3E6", borderRadius: 2, position: "relative"}}>
                                <div style={{display: "flex", justifyContent: "center", marginTop: "15px"}}>
                                    <Avatar alt="V" style={{width: "75px", height: "75px"}}>
                                        <img style={{objectFit: "cover",  filter: "grayscale(1)"}} src={cd} />
                                    </Avatar>
                                </div>
                                <div style={{display: "flex", justifyContent: "center", marginTop: "15px"}}>
                                    <div style={{display: "flex", flexDirection: "column"}}>
                                        <div style={{width: "100%", display: "flex", justifyContent: "center", marginLeft: "2%", marginRight: "2%"}}>
                                            <text style={{color: "white", fontSize: "18px"}}>
                                                Chinmay Deshpande, CTO
                                            </text>
                                        </div>
                                        <div style={{marginTop: "10px", marginLeft: "10%", marginRight: "10%", textAlign: "center"}}>
                                            <text style={{color: "white", fontSize: "12px"}}>
                                                Engineered and deployed automated trading products and built shipping logistics platforms.
                                            </text>

                                        </div>
                                    </div>
                                </div>
                                <div style={{position: "absolute", bottom: "10px", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                                    <div style={{borderTopWidth: 1, borderColor: "#E2E3E6", width: "90%", margin: "auto"}}>
                                        <text style={{color: "white", fontSize: "12px", fontFamily: "poppins"}}> Previously </text>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "center", paddingBottom: "3px"}}>
                                        <Box sx={{width: "27%"}}>
                                            <img style={{objectFit: "cover"}} src={coinbase} />
                                        </Box>
                                        <Box sx={{width: "10%"}}/>
                                        <Box sx={{width: "26%"}}>
                                            <img style={{objectFit: "cover"}} src={amazon}/>
                                        </Box>
                                    </div>
                                </div>
                            </Box>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default MobileAboutUs;