import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import {Auth0Provider} from "@auth0/auth0-react";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <Auth0Provider domain="dev-d5qzfskhrcurodg5.us.auth0.com"
             clientId="I7vFqediR2VXv3Oh4qbIi1zO2Aj7Jglf"
             authorizationParams={{
                 redirect_uri: "http://localhost:3000/dashboard",
             }}>
            <App/>
          </Auth0Provider>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
