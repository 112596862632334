import {useEffect, useState} from "react";
import MobileAboutUs from "../mobile/aboutus";
import AboutUs from "../aboutus";


function AboutPage() {
    const [mobile, setMobile] = useState(false)

    useEffect(() => {
        if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i)) {
            setMobile(true)
        }
    }, []);

    return(
        <div>
            {mobile ? <MobileAboutUs /> : <AboutUs/>};
        </div>
    )
}

export default AboutPage;